<template>
  <b-container data-aos="fade-up">
    <h1 class="mt-5 mb-2 text-primary">Accessibility</h1>
    <p>
      We are committed to be compliant with the Web Content Accessibility
      Guidelines (WCAG) 2.1 standard, to a level of AA rating as stipulated by
      the European Union Directive on the accessibility of the websites and
      mobile applications of public sector bodies.
    </p>

    <p>
      Any enquiries related to accessibility, please email
      <a href="mailto:thwvcs@gmail.com">thwvcs@gmail.com</a>.
    </p>

    <h2 class="text-primary">Known Accessibility Issues</h2>

    <p>
      We are not currently aware of any area of this website that does not meet
      the requirements of a AA rating.
    </p>
    <h3 class="text-primary">Contact</h3>
    <p>
      You can contact us if you have an accessibility issue. Either through email <a href="mailto:thwvcs@gmail.com">thwvcs@gmail.com</a>, or through phone <a href="tel:+447717178800">07717-17-88-00</a>
    </p>
  </b-container>
</template>

<script>
export default {
  name: "Accessibility",
};
</script>